/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@font-face {
    font-family: "Inconsolata";
    src: url("./assets/font/Inconsolata-VariableFont_wdth\,wght.ttf");
}

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

.sc-ion-label-ios-h,
.sc-ion-label-md-h {
    white-space: normal !important;
}

// ion-modal

ion-modal.modal-dimensions::part(content) {
    --height: auto;
    --width: 80%;
    max-height: 90vh;
    overflow-y: auto;
}

ion-modal.auto-height {
    --height: auto;
}
ion-modal.auto-height .ion-page {
    position: relative;
    display: block;
    contain: content;
}
ion-modal.auto-height .ion-page .inner-content {
    max-height: 80vh;
    overflow: auto;
}
.notification-change {
    color: black;
    text-align: left;
    font-size: 1.1rem;
}

ion-modal.modal-edit-dimensions::part(content) {
    --height: auto;
    --width: 90%;
    max-height: 90vh;
    overflow-y: auto;
}

ion-modal.modal-kiwiz::part(content) {
    border-radius: 15px;
    --height: fit-content;
    --width: 80%;
    --vertical-align: middle;
    --background: transparent;
}

ion-modal.adhoc-modal-dimensions::part(content) {
    --height: auto;
    --width: 85%;
}

// ion-toast

ion-toast {
    text-align: left;
    font-size: var(--ion-font-size-title);
    --border-radius: 0.25rem;
}

ion-toast.login-confirm-notification {
    color: #fff;
}

ion-toast.login-error-notification {
    color: #fff;
}

// ion-button

ion-button.form-modal {
    font-family: Inconsolata;
    font-size: 1rem;
    text-transform: none;
}

ion-button {
    --border-radius: 0px !important;
}

ion-button.secondary-btn {
    color: black;
}

// ion-progress-bar

ion-progress-bar {
    height: 10px;
}

// ion-row

ion-row.form-question-title {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 0.75rem;
    position: sticky;
    top: 0;
    background-color: var(--ion-background-color);
    z-index: 100;
}

ion-row.popup-title {
    font-size: 1.3rem;
    font-weight: bold;
}

ion-row.popup-subtitle {
    font-size: 1rem;
    margin-top: 0.5rem;
}

// ion-header

ion-header.detail-header {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: var(--ion-background-color);

    > ion-button.return-home-button {
        padding: 0 var(--ion-space-x-small);
    }
}

.alert-radio-label {
    white-space: normal !important;
}

.ion-color-started {
    --ion-color-base: var(--ion-color-started);
    --ion-color-base-rgb: var(--ion-color-started-rgb);
    --ion-color-contrast: var(--ion-color-started-contrast);
    --ion-color-contrast-rgb: var(--ion-color-started-contrast-rgb);
    --ion-color-shade: var(--ion-color-started-shade);
    --ion-color-tint: var(--ion-color-started-tint);
}

.ion-color-accepted {
    --ion-color-base: var(--ion-color-accepted);
    --ion-color-base-rgb: var(--ion-color-accepted-rgb);
    --ion-color-contrast: var(--ion-color-accepted-contrast);
    --ion-color-contrast-rgb: var(--ion-color-accepted-contrast-rgb);
    --ion-color-shade: var(--ion-color-accepted-shade);
    --ion-color-tint: var(--ion-color-accepted-tint);
}

.ion-color-confirmed {
    --ion-color-base: var(--ion-color-confirmed);
    --ion-color-base-rgb: var(--ion-color-confirmed-rgb);
    --ion-color-contrast: var(--ion-color-confirmed-contrast);
    --ion-color-contrast-rgb: var(--ion-color-confirmed-contrast-rgb);
    --ion-color-shade: var(--ion-color-confirmed-shade);
    --ion-color-tint: var(--ion-color-confirmed-tint);
}

.ion-color-invalid-login {
    --ion-color-base: var(--ion-color-invalid-login);
    --ion-color-base-rgb: var(--ion-color-invalid-login-rgb);
    --ion-color-contrast: var(--ion-color-invalid-login-contrast);
    --ion-color-contrast-rgb: var(--ion-color-invalid-login-contrast-rgb);
    --ion-color-shade: var(--ion-color-invalid-login-shade);
    --ion-color-tint: var(--ion-color-invalid-logi-tint);
}

.ion-color-login-warning {
    --ion-color-base: var(--ion-color-login-warning);
    --ion-color-base-rgb: var(--ion-color-login-warning-rgb);
    --ion-color-contrast: var(--ion-color-login-warning-contrast);
    --ion-color-contrast-rgb: var(--ion-color-login-warning-contrast-rgb);
    --ion-color-shade: var(--ion-color-login-warning-shade);
    --ion-color-tint: var(--ion-color-login-warning-tint);
}
